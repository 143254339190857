import React from 'react'
import GatsbyLink from '../src/components/GatsbyLink'
import { Image } from '../src/components/Image'
import myImage from "../static/logos/logo_primary.png"
import './Logo.scss'

export const Logo = props => {
  return (
    <GatsbyLink to="/" className="logo">
      <img src={myImage} />
    </GatsbyLink>
  );
}