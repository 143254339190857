import React from 'react';
import Helmet from 'react-helmet';
import { useInjectableScript } from '../__HOOKS/use-injectable-script';
import Header from '../Header';
import Footer from '../Footer';
import { addExternalCSS } from '../../utils/helpers'
import '../../sass/global/styles.scss';

const Layout = ({ children, location, wordpressUrl, includeCss = false }) => {
  
  const injectableScriptData = useInjectableScript();
  const injectableScript = injectableScriptData.wordpressAcfOptions.options.injectableScript;
  return (
    <>
      
      <div id="layout">
        {includeCss && addExternalCSS(`${wordpressUrl}/wp-admin/admin-ajax.php?action=dynamic_css`, 'contrabass')}
        <Helmet>
          {/* IO Polyfill Observer */}
          <script src="https://polyfill.io/v3/polyfill.min.js?features=IntersectionObserver"></script>
          {injectableScript &&
            <script>
              {injectableScript}
            </script>
          }
        </Helmet>
        <Header location={location} />
        <div className="main">{children}</div>
        <Footer location={location} />
      </div>
    </>
  )
}

export default Layout
